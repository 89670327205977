// ** Initial State
const initialState = {
  allData: [],
  allAddons: [],
  data: [],
  total: 1,
  params: {},
  currentUser: null,
  selectedUser: null,
  selectedAddonData: localStorage.getItem("selectedAddonUpdateData") === 'undefined' ? null : JSON.parse(localStorage.getItem("selectedAddonUpdateData")),
  bookEdit: false,
  customPagination: null,
  token: JSON.parse(localStorage.getItem("accessToken"))
}



const addons = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ADDONS':
      return { ...state, allAddons: action.data, customPagination: action.customTotal }
    case 'GET_BOOK_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER':

      return { ...state, selectedUser: action.selectedUser }
    case 'ADD_USER':
      return { ...state, allData: action.data }
    case 'DELETE_ADDON':

      return { ...state, allAddons: action.data }
    case 'EDITADDON':
      return { ...state, selectedAddonData: action.data }
    case 'ADDON_PAGE_CHANGE':
      return { ...state, allAddons: [] }
    // case 'START_LOADING':
    //   return {...state, loading: true}
    // case 'END_LOADING':
    //   return {...state, loading: false}
    default:
      return { ...state }
  }
}
export default addons
