import { store } from '@store/storeConfig/store'
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  currentUser: null,
  selectedUser: localStorage.getItem("selectedUser") === 'undefined' ? null : JSON.parse(localStorage.getItem("selectedUser")),
  customTotal: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  getError: false,
  errorMsg: 'Error occured!!',
  otpSuccess: false,
  setNewPassword: false,
  found_results: '',
  entries_per_page: '',
  total_results: '',
  fetching: false,
  updatePagination: 0,
  loading: false,
  isTokenExpire: false

}




const admins = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ADMINS':
      return { ...state, allData: action.data, customTotal: action.total, entries_per_page: action.entries_per_page, found_results: action?.found_results, total_results: action?.total_results }
    case 'GET_ADMIN_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_ADMIN':

      return { ...state, selectedUser: action.selectedUser, fetching: false }
    case 'ADD_ADMIN':
      return { ...state, allData: action.data }
    case 'DELETE_USER':
      return { ...state, updatePagination: action.data }
    case 'ADMIN_PAGE_CHANGE':
      return { ...state, allData: [] }
    case 'GET_ERROR':
      return { ...state, getError: true, errorMsg: action.error }
    case 'GET_ERROR_NOTI_CLOSE':
      return { ...state, getError: false }
    case 'GET_OTP':
      return { ...state, otpSuccess: action?.data }
    case 'SET_NEW_PASSWORD':
      return { ...state, setNewPassword: action?.data, otpSuccess: false }
    case 'ADMINFETCHING':
      return { ...state, fetching: true }
    case 'FINISHEDFETCHING':
      return { ...state, fetching: false }
    case 'START_LOADING':
      return { ...state, loading: true }
    case 'END_LOADING':
      return { ...state, loading: false }
    case 'TOKEN_EXPIRE':
      return {
        ...state,
        isTokenExpire: true
      }
    default:
      return { ...state }
  }
}
export default admins
