// ** Initial State
const initialState = {
  allData: [],
  allPackages: [],
  data: [],
  total: 1,
  params: {},
  currentUser: null,
  selectedUser: null,
  selectedPackage: localStorage.getItem("selectedPackage") === 'undefined' ? null : JSON.parse(localStorage.getItem("selectedPackage")),
  bookEdit: false,
  packagePagi: null,
  token: JSON.parse(localStorage.getItem("accessToken"))
}



const packages = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_PACKAGES':
      return { ...state, allPackages: [...action.data], packagePagi: action.packagePagi }
    case 'GET_PACKAGE_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER':

      return { ...state, selectedUser: action.selectedUser }
    case 'ADD_USER':
      return { ...state, allData: action.data }
    case 'DELETE_PACKAGE':

      return { ...state, allPackages: action.data }
    case 'EDITPACKAGE':
      return { ...state, selectedPackage: action.data }
    case 'PACKAGE_PAGE_CHANGE':
      return { ...state, allPackages: [] }
    default:
      return { ...state }
  }
}
export default packages
